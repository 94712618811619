body {
    background-color: #000000;
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

h1 {
    color: white;
    font-family: 'Akatab';
    font-weight: 900;
    font-size: 65px;
}

h2 {
    color: #f2e1ee;
    font-family: 'Overpass';
    font-weight: 800;
    font-size: 50px;

    &.fancyButtonTitle {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        padding: 0;
        transform: translateX(-15%);
        transition-property: transform;
        transition-duration: 0.75s;

        &.hovered {
            transform: translateX(0);
        }
    }
}

h3 {
    color: #bd9bb4;
    font-family: 'Albert Sans';
    font-weight: 200;
    font-size: 25px;
    max-width: 80vw;
    text-decoration: none;

    transition-property: transform color;
    transition-duration: 0.75s;
    transition-timing-function: ease;

    &:hover {
        transform: scale(1.1, 1.1);
    }

    &.headerButton {
        transition-duration: 0.5s;
        color: white;
        transition-property: transform color;
        font-weight: 10;
        font-size: 20px;

        &:hover {
            transform: scale(1.4);
            color: #bd9bb4;
        }
    }
}

p {
    color: #d9ccd5;
    font-family: 'Albert Sans';
    font-weight: 100;
    font-size: 20px;

    &.legalText {
        font-family: monospace;
        font-size: 12px;
        color: #868386;
    }

    &.aboveInput {
        margin-bottom: 2px;
        padding-bottom: 0;
    }
}

h1, h2, h3, p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    &.alignLeft {
        margin-left: 5%;
        text-align: left;
        max-width: 75%;
    }

    &.alignRight {
        margin-right: 5%;
        text-align: right;
        max-width: 75%;
    }

    &.glow {
        $glowColor: #f8d3ee;
        color: #fff;
        text-shadow: 0 0 15px $glowColor;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    opacity: 1.0;
    background-color: transparent;
    margin: 0 auto;
    padding: 0;
    border: none;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

div {
    margin: none auto;
    padding: none;
    border: none;
    display: block;

    &.popupWrapper {
        $width: 80vw;
        width: $width;
        left: calc((100vw - $width) / 2);

        $height: 85vh;
        height: $height;
        top: calc((100vh - $height) / 2);

        border-radius: 10px;
        position: fixed;
        background-color: #040305;
        border: 5px solid #333333;
        box-shadow: 0 0 50px 5px #09010f;
        opacity: 0.975;
        z-index: 99;
        overflow-y: scroll;
        overflow-x: hidden;

        transform: translateY(10vw) scale(0);
        transition-property: transform;
        transition-duration: 1s;
        transition-timing-function: ease;

        &.shown {
            transform: translateY(0) scale(1);
        }
    }

    &#headerWrapper {
        height: 100px;
        background-color: #070707;
        z-index: 98;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;

        opacity: 0.8;
        transition-property: opacity;
        transition-duration: 0.5s;
        transition-timing-function: ease;

        &:hover {
            opacity: 1.0;
        }
    }

    &.headerCellWhiteLine {
        width: 75%;
        height: 5px;
        background-color: white;
        margin-left: auto;
        margin-right: auto;

        transform: scale(0, 1);
        transition-property: transform;
        transition-duration: 0.5s;
        transition-timing-function: ease;

        &.shown {
            transform: scale(1);
        }
    }

    &.fancyButtonWrapper {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1vh;
        padding-bottom: 25px;
        padding-top: 50px;
        border-radius: 10px;
        overflow-x: hidden;

        background-color: rgba(50, 50, 50, .25);
        box-shadow: 1px 1px 10px 2px rgba(8, 8, 8, 0.9);

        transition-duration: 0.75s;
        transition-property: transform background-color;
        transition-timing-function: ease-out;
        overflow-y: hidden;
        
        &.hovered {
            transform: scale(1.1);
            background-color: #2b1b28;
        }
    }

    $purpleBackgroundColor: #30112c;

    &.intoPurple {
        background-image: linear-gradient(#000000, $purpleBackgroundColor);
    }

    &.purple {
        background-color: $purpleBackgroundColor;
    }

    &.outOfPurple {
        background-image: linear-gradient($purpleBackgroundColor, #000000);
    }
}

img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;

    &.mainImage {
        box-shadow: 0 0 50px 5px #0f0118;
        border-radius: 10px;
        width: 85%;
        max-height: 80vh;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
    }

    &.centered {
        margin: auto;
    }
}

input {
    margin: auto;
    display: block;
    width: 40%;
    height: 22px;
    background-color: #101010;
    border: 2px solid #2e2030;
    border-radius: 5px;
    outline: none;
    color: #d9ccd5;
    font-family: 'Albert Sans';
    
    transition-property: background-color transform opacity border;
    transition-duration: 0.5s;

    &:focus {
        background-color: #1b171a;
        transform: scale(1.05);
        border: 2px solid #aa87c9;
    }

    &.popup {
        opacity: 0.75;

        &:focus {
            opacity: 1.0;
        }
    }

    &.submit {
        background-color: unset !important;
        border: unset;
        height: auto;
        font-size: 25px;
        font-weight: 200;
        font-family: 'Albert Sans';
        $marginTopBottom: 10px;
        margin-top: $marginTopBottom;
        margin-bottom: $marginTopBottom;
        color: #bd9bb4;

        transition-property: transform;
        transition-duration: 0.75s;
        transition-timing-function: ease;

        &:hover {
            transform: scale(1.1);
        }
    }
}

table {
    table-layout: fixed;
    width: 100%;
}

td {

    &.headerCell{
        background-color: #070707;
        border-radius: 10px;
        transition-property: background-color;
        transition-duration: 0.5s;
        transition-timing-function: ease;

        &.hovered {
            background-color: #302a30;
        }
    }
}

span {

    &.fancyButtonArrow{
        padding: 0;
        margin-top: 10px;
        display: block;
        margin-bottom: 0;

        opacity: 0;
        transform: rotate(-180deg);
        transition-property: opacity transform;
        transition-duration: 0.75s;

        @keyframes showAnimation {
            0% {
                transform: rotate(-180deg);
            }
            10% {
                transform: rotate(-172deg);
            }
            100% {
                transform: rotate(0deg);
            }
        }

        &.hovered {
            opacity: 1.0;
            transform: rotate(0deg);
            
            animation-name: showAnimation;
            animation-duration: 0.75s;
        }
    }
}

video {
    display: block;
    max-width: 75%;
    max-height: 75vh;
    margin: auto;
    border-radius: 25px;
    border: 5px solid #202020;
}

.rounded {
    display: block;
    border-radius: 50%;
}

.noVerticalSpacing {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}